import { useSelector } from "react-redux";
import {
  SelectCurrentFilters,
  SelectAllFilters,
  SelectCurrentProject,
} from "../../features/projects/projectSlice";
import { selectActiveRole } from "../../features/auth/authSlice";
import { useGetDenoraDataQuery } from "../../features/report/denoraApiSlice";
import Loader from "../Loader";
import ViolinLayout from "../graph/ViolinLayout";
import LocationFilter from "../../layout/components/LocationFilter";
import { SelectSameLocationFilterValue } from "../../features/projects/projectSlice";
import { useMemo, useState } from "react";
import PageDescription from "../general/PageDescription";

const DenoraPage = ({ api_group }) => {
  const pid = useSelector(SelectCurrentProject);
  const selectedFilters = useSelector(SelectCurrentFilters);
  const filterArray = useSelector(SelectAllFilters);
  const activeRole = useSelector(selectActiveRole);
  const { data, isLoading, isSuccess, isError, error } = useGetDenoraDataQuery({
    pid,
    api_group,
  });

  const sameLocationFilterValue = useSelector(SelectSameLocationFilterValue);

  // Handle filter checking memoization
  const isFiltered = useMemo(() => {
    return (
      activeRole === "user_admin" &&
      selectedFilters.length > 0 &&
      selectedFilters[0] !== "Overall"
    );
  }, [activeRole, selectedFilters]);

  const selectedFilterKey = selectedFilters[0];
  const filter = filterArray[selectedFilterKey];
  let input_data = null;
  if (isLoading) {
    return <Loader />;
  } else if (isSuccess) {
    //inputData = filterData(scatterData.items, filterArray[selectedFilters[0]]);
    if (sameLocationFilterValue) {
      input_data = data.scale_data.map((subArray) =>
        subArray.filter((item) => item.same_location)
      );
    } else {
      input_data = data.scale_data;
    }
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  const pageText = `
  ## How to read the following plots

### Scale data

A scale score is the result of combining two or more answers, usually from likert questions (agreement on a scale from 1 to 6). Each participant to the survey gets a score, and the plot below shows the distribution of the scores for all the participants.
The wave-like shape is proportional to the number of respondents that received each score: regions of the plot where the shape is larger mean that a lot of respondents fall in that score range. On the other hand, in regions that are narrower fall fewer respondents.
The _vertical line_ you see represents the **average value** of the whole distribution, while the _dot_ is **your score**. This way you can quickly see wether your score falls above or below average, and even wether your point of view is _common_ among your colleagues or not.
  `;
  return (
    <>
      <LocationFilter selectedFilters={selectedFilters} />
      <div className="container-fluid">
        <PageDescription
          pageText={pageText}
          titles={data.scale_titles}
          descriptions={data.scale_descriptions}
        />
        <div className="row">
          {input_data &&
            input_data.map((item, i) => (
              <ViolinLayout
                key={i}
                dataset={item}
                filtered={isFiltered}
                selected_filter={filter}
                userValue={data.scale_user_values[i]}
                benchmarkValue={data.scale_benchmark_values[i]}
                title={data.scale_titles[i]}
                info_button={true}
                scale_info={data.scale_descriptions[i]}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default DenoraPage;
