import React from "react";
import { paths } from "../../config/routes";
import { useNavigate, useLocation } from "react-router-dom";
import { SelectCurrentProject } from "../../features/projects/projectSlice";
import { useSelector } from "react-redux";
import { selectActiveRole } from "../../features/auth/authSlice";

export const MenuFooter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentProject = useSelector(SelectCurrentProject)
  const activeRole = useSelector(selectActiveRole);

  const handleOnClick = (path) => {
    navigate(path);
  };

  const inSettings = location.pathname.includes(paths.settings) || location.pathname.includes(paths.project_settings);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "auto",
      }}
    >
      {!inSettings  && (
          <div className="sidebarFooter">
            <div
              role="button"
              onClick={() => {
                handleOnClick("/settings/profile");
              }}
            >
              Account
            </div>
            {currentProject && activeRole === "user_admin" && <div
              role="button"
              onClick={() => {
                handleOnClick("/project-settings/employees");
              }}
            >
              {" "}
              Project Settings
            </div>}
          </div>
        )}
        {inSettings && (
          <div className="sidebarFooter">
            <div
              role="button"
              onClick={() => {
                handleOnClick("/");
              }}
            >
              Home
            </div>
          </div>
        )}
    </div>
  );
};
