import { combineReducers, configureStore, AnyAction } from "@reduxjs/toolkit";
import projectReducer from "../features/projects/projectSlice";
import { apiSlice } from "../api/apiSlice";
import authReducer from "../features/auth/authSlice";
import modalReducer from "../features/modal/modalSlice";
import contestReducer from "../features/report/contestSlice";

const combinedReducer = combineReducers({
  projects: projectReducer,
  modal: modalReducer,
  contest:contestReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
 });

 const rootReducer = (state, action) => {
  if (action.type === 'RESET') { //We are calling this RESET, but call what you like!
     state = {};
  }
  return combinedReducer(state, action);
 };

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});
