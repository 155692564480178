import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/auth/authSlice";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import usePersist from "../../hooks/usePersist";

function Login() {
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [persist, setPersist] = usePersist();

  const [login] = useLoginMutation();
  const dispatch = useDispatch();

  const loginImageSrc = process.env.REACT_APP_CUSTOM_LOGIN_IMAGE || "login.webp"
  console.log(loginImageSrc)

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const userData = await login({ user, pwd }).unwrap();
      dispatch(setCredentials({ ...userData, user }));
      setUser("");
      setPwd("");
      if (location.state?.from) {
        navigate(location.state.from);
      } else {
        navigate("/");
      }
    } catch (err) {
      if (!err?.status) {
        // isLoading: true until timeout occurs
        setErrMsg("No Server Response");
      } else if (err.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.status === 401) {
        setErrMsg("Login Failed");
      } else {
        setErrMsg("Login Failed");
      }
    }
  };

  const handleToggle = () => setPersist((prev) => !prev);

  return (
    <div class="container-flex">
      <div class="row">
        <div class="image-side col-12 col-md-6">
          <img className="login-img" src={loginImageSrc} alt="" />
        </div>
        <div class="form-side col-12 col-md-6">
          <div className="container login-content style-2">
            <div className="row no-gutters justify-content-center">
              <div className="col-12 ">
                <div id="sign-in" className="auth-form form-validation">
                  {errMsg && (
                    <div className="login-error border p-1 my-2">{errMsg}</div>
                  )}
                  <form onSubmit={handleSubmit} className="form-validate">
                    <h3 className="text-center mb-4 text-black">
                      Sign in your account
                    </h3>
                    <div className="form-group mb-3">
                      <label className="mb-1" htmlFor="val-email">
                        <strong>Email</strong>
                      </label>
                      <div>
                        <input
                          type="email"

                          value={user}
                          onChange={(e) => setUser(e.target.value)}
                          placeholder="Type Your Email Address"
                        />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="mb-1">
                        <strong>Password</strong>
                      </label>
                      <input
                        type="password"
                        value={pwd}
                        placeholder="Type Your Password"
                        onChange={(e) => setPwd(e.target.value)}
                      />
                    </div>
                    <div className="form-row d-flex justify-content-between mt-4 mb-2">
                      <div className="form-group mb-3">
                        <div className="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="basic_checkbox_1"
                            onChange={handleToggle}
                            checked={persist}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="basic_checkbox_1"
                          >
                            Remember my preference
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="text-end form-group mb-3">
                      <button
                        type="submit"
                        className="btn btn-primary submit-button"
                      >
                        Sign In
                      </button>
                    </div>
                  </form>
                  <div className="form-group mb-3">
                    <p>
                      <Link className="link" to={"/forgot"}>Forgot Password ?</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
