import { useSelector, useDispatch } from "react-redux";
import { selectActiveRole } from "../../features/auth/authSlice";
import {
  SelectSameLocationFilter,
  SelectSameLocationFilterValue,
} from "../../features/projects/projectSlice";
import { toggleSameLocationFilter } from "../../features/projects/projectSlice";

function LocationFilter() {
  const activeRole = useSelector(selectActiveRole);
  const dispatch = useDispatch();
  const sameLocationFilter = useSelector(SelectSameLocationFilter);
  const sameLocationFilterValue = useSelector(SelectSameLocationFilterValue);
  
  return (
    sameLocationFilter && (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 py-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="row justify-content-left">
                      <div className="col-lg-4 pt-4 ">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={sameLocationFilterValue}
                            onChange={() => {
                              dispatch(toggleSameLocationFilter());
                            }}
                            id="flexSwitchCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckDefault"
                          >
                            Filter by your Location
                          </label>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default LocationFilter;
