import { useState } from "react";
import { Card, Button } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

const PageDescription = ({ pageText, titles, descriptions }) => {
  const [open, setOpen] = useState(false);

  // create the full markdown text
  let datastring = pageText;
  if (titles) {
    datastring =
      datastring +
      titles
        .map((item, index) => `\n\n ###### ${item} \n\n ${descriptions[index]}`)
        .join("\n");
  }

  // check if text is long enoigh to create and display a preview

  const lines = datastring.trim().split("\n");
  const showPreview = lines.length > 15;
  let previewContent = "";

  if (showPreview) {
    const previewEnd = /^#+\s/.test(lines[9]?.trim()) ? 9 : 10;
    previewContent = lines.slice(0, previewEnd).join("\n");
  }

  return (
    <>
      <Card className="custom-card border-0">
        <Card.Body>
          <ReactMarkdown>
            {open || !showPreview ? datastring : previewContent}
          </ReactMarkdown>

          {showPreview && (
            <div className="d-flex justify-content-start">
              <Button
                variant="primary"
                className="mt-0"
                onClick={() => setOpen(!open)}
              >
                {open ? "Hide" : "Read More..."}
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default PageDescription;
