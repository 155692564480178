import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { selectActiveRole } from "./features/auth/authSlice.js";
import Login from "./pages/auth/login.js";
import ForgotPassword from "./pages/auth/forgot-password.js";
import ResetPassword from "./pages/auth/reset-password.js";
import ChangeEmail from "./pages/auth/change-email.js";
import React, { lazy, Suspense } from "react";
import Loader from "./components/Loader";
import "./styles/global.scss";
import { paths } from "./config/routes.js";
import AuthGuard from "./features/auth/AuthGuard";
import AdminGuard from "./features/auth/AdminGuard.js";
import ProjectsGuard from "./features/projects/ProjectsGuard.js";
import PersistLogin from "./features/auth/PersistLogin";
import Home from "./pages/Home";
import { mainMenu, projectSettingsMenu, accountMenu } from "./config/routes.js";

import Page from "./pages/Page.js";
import Dashboard from "./pages/Dashboard.js";
//const Home = lazy(() => import("./pages/Home"));
const NoPage = lazy(() => import("./pages/NoPage"));
const NoProject = lazy(() => import("./pages/NoProject"));

const generateRoutes = (activeRole, itemArray) => {
  if (!activeRole || !itemArray) return null;

  return itemArray.flatMap((item) => {
    if (item.roles.includes(activeRole)) {
      if (item.component) {
        return (
          <Route
            key={item.label}
            path={item.path}
            element={
              <Page
                menuArray={itemArray}
                title={item.label}
                component={<item.component api_group={item.api_group} />}
              />
            }
          />
        );
      }
      if (item.subItems) {
        return item.subItems.map((subItem) => (
          <Route
            key={`${item.path}${subItem.path}`}
            path={`${item.path}${subItem.path}`}
            element={
              <Page
                menuArray={itemArray}
                title={subItem.label}
                component={<subItem.component group={subItem.api_group} />}
              />
            }
          />
        ));
      }
    }
    return null;
  });
};

const router = (activeRole) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path={paths.login} element={<Login />} />
        <Route path={paths.forgot} element={<ForgotPassword />} />
        <Route path={paths.reset} element={<ResetPassword />} />
        <Route path={paths.changeEmail} element={<ChangeEmail />} />
        <Route element={<PersistLogin />}>
          <Route element={<AuthGuard />}>
            <Route element={<ProjectsGuard />}>
              <Route index element={<Home />} />
              <Route
                path={"/dashboard"}
                element={
                  <Page
                    menuArray={mainMenu}
                    title={"Dashboard"}
                    component={<Dashboard />}
                  />
                }
              />
              <Route element={<AdminGuard />}>
                {generateRoutes(activeRole, projectSettingsMenu)}
              </Route>
              {generateRoutes(activeRole, mainMenu)}
            </Route>
            {generateRoutes(activeRole, accountMenu)}
            <Route
              path={paths.welcome}
              element={
                <Page
                  menuArray={[]}
                  title={"Welcome at Trustie"}
                  component={<NoProject />}
                />
              }
            />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Route>
      </Route>
    )
  );

const App = () => {
  const activeRole = useSelector(selectActiveRole);

  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={router(activeRole)} />
    </Suspense>
  );
};

export default App;
