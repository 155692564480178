import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    id: null,
    name: null,
    surname: null,
    user: null,
    token: null,
    loggedOut: false,
    roles: [],
    activeRole: null,
    permissions: [],
    licenses: [],
    modules: [],
    projects: [],
    systemModules: [],
    starGroups : []
  },
  reducers: {
    setCredentials: (state, action) => {
      const { id, name, surname, user, accessToken, roles, permissions, licenses, modules, projects, systemModules, starGroups } =
        action.payload;
      function setActiveRole() {
        if (roles.includes("user_admin")) return "user_admin"
        else if (roles.includes("trustie_user")) return "trustie_user"
        else if (roles.includes("denora_user")) return "denora_user"
        else return null
      };
      state.activeRole = setActiveRole();
      state.id = id;
      state.name = name;
      state.surname = surname;
      state.user = user;
      state.token = accessToken;
      state.roles = roles;
      state.permissions = permissions;
      state.licenses = licenses;
      state.modules= modules
      state.projects = projects
      state.systemModules = systemModules;
      state.starGroups = starGroups;
    },
    setLogout: (state) => {
      state.id = null;
      state.name = null;
      state.surname= null;
      state.user = null;
      state.token = null;
      state.loggedOut= true;
      state.roles = [];
      state.activeRole = null;
      state.permissions = [];
      state.licenses = [];
      state.modules = [];
      state.projects = [];
      state.systemModules = [];
      state.starGroups = [];
    },
    setActiveRole: (state, action) => {
      if (state.roles.includes(action.payload)){
        state.activeRole = action.payload;
      }
    },
    
  },
});

export const { setCredentials, setLogout, setActiveRole } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.id;
export const selectCurrentUSerName = (state) => [state.auth.name, state.auth.surname];
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentLoggedOut = (state) => state.auth.loggedOut;
export const getCurrentLoginStatus = (state) =>
  state.auth.token ? true : false;
export const selectCurrentRoles = (state) => state.auth.roles;
export const selectActiveRole = (state) => state.auth.activeRole;
export const selectCurrentPermissions = (state) => state.auth.permissions;
export const selectCurrentLicenses = (state) => state.auth.licenses;
export const selectCurrentModules = (state) => state.auth.modules;
export const selectAllModules = (state) => [state.auth.modules, state.auth.systemModules]
export const selectAllStarGroups = (state) => state.auth.starGroups;
export const selectCurrentProjects = (state) => state.auth.projects;
