import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from "./App";
import { store } from './app/store';
import { Provider } from 'react-redux';


const updateFavicon = (customDirectory) => {
    const link = document.querySelector("link[rel~='icon']") || document.createElement("link");
    link.rel = "icon";
    link.href = `${process.env.PUBLIC_URL}${customDirectory}`;
    document.head.appendChild(link);
  };

const root = ReactDOM.createRoot(document.getElementById('root'));

// Set favicon dynamically depending on .env variable
const favicon = process.env.REACT_APP_CUSTOM_FAVICON || "favicon.png"; // Default fallback
console.log(favicon)
updateFavicon(favicon);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
