import { apiSlice } from "../../api/apiSlice"

export const denoraApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDenoraData: builder.query({
            query: (api_params) => {
                if (api_params.pid && api_params.api_group ) return `report/denora/${api_params.pid}/api_group/${api_params.api_group}`;
                throw new Error("ID or api_group is undefined");
            },
            keepUnusedDataFor: 600,
        })
    })
})

export const {
    useGetDenoraDataQuery
} = denoraApiSlice 