import Chip from "../components/Chip";
import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectActiveRole } from "../features/auth/authSlice";
import {
  SelectAllFilters,
  updateFilters,
} from "../features/projects/projectSlice";

function FilterBar({ selectedFilters }) {
  const activeRole = useSelector(selectActiveRole);
  const filters = Object.keys(useSelector(SelectAllFilters));
  const [filtersState, setFiltersState] = useState({
    opacity: 0,
    pointerEvents: "none",
  });
  const filterListRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      filterListRef.current &&
      !filterListRef.current.contains(event.target)
    ) {
      setFiltersState({ opacity: 0, pointerEvents: "none" });
    }
  };

  const handleEscapeKey = (event) => {
    if (event.key === "Escape") {
      setFiltersState({ opacity: 0, pointerEvents: "none" });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const dispatch = useDispatch();
  const handleFilterChange = (e) => {
    dispatch(updateFilters(e));
    setFiltersState({
      opacity: 0,
      pointerEvents: "none",
    });
  };

  return (
    activeRole === "user_admin" && (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 py-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="row align-items-center">
                    {activeRole === "user_admin" && (
                      <div className="col-auto filters-nav ">
                        <img
                          src="/icons/filter-icon.svg"
                          onClick={() =>
                            setFiltersState({
                              opacity: 1,
                              pointerEvents: "all",
                            })
                          }
                          alt=""
                          className="filter-icon"
                        />
                        <div
                          className="filter-list "
                          ref={filterListRef}
                          style={filtersState}
                        >
                          <img
                            onClick={() =>
                              setFiltersState({
                                opacity: 0,
                                pointerEvents: "none",
                              })
                            }
                            src="/icons/close-filters.svg"
                            alt=""
                            className="close-filters"
                          />
                          {filters.map((e, i) => {
                            return (
                              <div
                                role="button"
                                onClick={() => handleFilterChange(e)}
                                key={"filter_" + i}
                                className="filter"
                              >
                                {e}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    {filtersState.opacity === 0 && (
                      <>
                        <div className="col-auto">filter by</div>
                        {selectedFilters.map((e, i) => {
                          return (
                            <div key={"chip_" + i} className="col-auto">
                              <Chip text={e} />
                              {/*<Chip onClose={onChipClosed} text={e}/>*/}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
    
  );
}

export default FilterBar;
