import { Link } from "react-router-dom";

function MenuHeader() {
  const menuLogoSrc = process.env.REACT_APP_CUSTOM_MENU_LOGO || "menu_logo.webp"

  return (
    <div className="menu-header">
      <Link to="/">
        <img src={menuLogoSrc} height={36} alt="" />
      </Link>
    </div>
  );
}

export default MenuHeader;
