import Trust from "../components/page/Trust.js";
import TrustBehaviours from "../components/page/TrustBehaviours.js";
import PsychologicalSafety from "../components/page/PsychologicalSafety.js";
import Identification from "../components/page/Identification.js";
import Values from "../components/page/Values.js";

import LeadersDiscovery from "../components/page/LeadersDiscovery.js";
import ChangeAgents from "../components/page/ChangeAgents.js";
import NetworkRelations from "../components/page/NetworkRelations.js";
//import NetworkTeam from "../components/page/NetworkTeam.js";

import Wellbeing from "../components/page/Wellbeing.js";
import Inclusion from "../components/page/Inclusion.js";
import Inail from "../components/page/Inail.js";

import WorkNeeds from "../components/page/WorkNeeds.js";
import Climate from "../components/page/Climate.js";
import JobSatisfaction from "../components/page/JobSatisfaction.js";
//import LeadershipStyle from "../components/page/LeadershipStyle.js";

import ImpactAnalysis from "../components/page/ImpactAnalysis.js";
import LongitudinalAnalysis from "../components/page/LongitudinalAnalysis.js";

import Participation from "../components/page/Participation.js";
import Compilation from "../components/page/Compilation.js";
import Distribution from "../components/page/Distribution.js";

import Account from "../pages/settings/Account.js";
import AccountSecurity from "../pages/settings/AccountSecurity.js";
import AccountSetting from "../pages/settings/AccountSetting.js";
import ProjectEmployees from "../pages/ProjectEmployees.js";

import DenoraPage from "../components/page/DenoraPage.js";

export const paths = {
  dashboard: "/dashboard",
  login: "/login",
  forgot: "/forgot",
  reset: "/reset-password/:token",
  changeEmail: "/change-email/:token",
  welcome: "/welcome",
  trust_integrity: "/trust-integrity",
  my_report: "/my-report",
  survey_invites: "/survey-invites",
  project_employees: "/project-employees",
  settings: "/settings",
  wellbeing_inclusion: "/wellbeing-inclusion",
  leader_impact_people: "/leader-impact-people",
  job_leadership_climate: "/job-leadership-climate",
  analytics: "/analytics",
  temporal_analysis: "/temporal-analysis",
  project_settings: "/project-settings"
};

export const mainMenu = [
  {
    label: "Dashboard",
    path: "/dashboard",
    roles: ["user_admin", "trustie_user", "denora_user"],
    link: "/dashboard",
    component: null // this is the default home route and is defined in the app.js route for all roles
  },
  {
    label: "Values, Identification & Integrity",
    path: "/values-identification",
    roles: ["user_admin", "trustie_user"], 
    subItems: [
      {
        label: "Values",
        path: "/values",
        roles: ["user_admin"],
        component: Values
      },
      {
        label: "Organizational Identification",
        path: "/identification",
        roles: ["user_admin", "trustie_user"],
        component: Identification
      }
    ],
  },
  {
    label: "Trust & Inclusion",
    path: "/trust-inclusion",
    roles: ["user_admin", "trustie_user"], 
    subItems: [
      {
        label: "Social Network",
        path: "social-network",
        roles: ["user_admin"],
        component: NetworkRelations
      },
      {
        label: "Trust",
        path: "/trust",
        roles: ["user_admin", "trustie_user"],
        component: Trust
      },
      {
        label: "Psychological Safety",
        path: "/safety",
        roles: ["user_admin", "trustie_user"],
        component: PsychologicalSafety
      },
      {
        label: "Inclusion",
        path:"/inclusion",
        roles: ["user_admin", "trustie_user"],
        component: Inclusion
      },
      {
        label: "Trust Promoting Behaviours",
        path: "/behaviours",
        roles: ["user_admin"],
        component: TrustBehaviours
      },
    ],
  },
  {
    label: "Leaders & Change Agents Discovery",
    path:"/leader-impact-people",
    roles: ["user_admin"],
    subItems: [
      { 
        label: "Leaders Discovery",
        path: "leaders-discovery",
        roles: ["user_admin"],
        component: LeadersDiscovery
      },
      {
        label: "Change Agents Discovery",
        path: "change-agents",
        roles: ["user_admin"],
        component: ChangeAgents
      },
    ],
  },
  {
    label: "Wellbeing & WRS",
    path:"/wellbeing-wrs",
    roles: ["user_admin"],
    subItems: [
      {
        label: "Individual Wellbeing",
        path:"/wellbeing",
        roles: ["user_admin", "trustie_user"],
        component: Wellbeing
      },
      
      {
        label: "Work Related Stress",
        path:"/work-stress",
        roles: ["user_admin", "trustie_user"],
        component: Inail
      },
    ],
  },
  {
    label: "Job Satisfaction & Climate",
    path: "/satisfaction-climate",
    roles: ["user_admin", "trustie_user"],
    subItems: [
      {
        label: "Work Needs Alignment",
        path:"work-needs",
        roles: ["user_admin"],
        component: WorkNeeds
      },
      {
        label: "Climate and Support",
        path:"climate-support",
        roles: ["user_admin", "trustie_user"],
        component: Climate
      },
      {
        label: "Job Satisfaction",
        path:"job-satisfaction",
        roles: ["user_admin", "trustie_user"],
        component: JobSatisfaction
      },
    ],
  },
  {
    label: "Time Trend Analysis",
    path: "/time-trend-analysis",
    roles: ["user_admin"],
    subItems: [
      {
        label: "Impact Analysis",
        path:"/impact",
        roles: ["user_admin"],
        component: ImpactAnalysis
      },
      {
        label: "Longitudinal Analysis",
        path:"/longitudinal",
        roles: ["user_admin"],
        component: LongitudinalAnalysis
      },
    ],
  },
  {
    label: "My Job",
    path: "/my-job",
    api_group: 5,
    roles: ["denora_user"],
    component: DenoraPage 
    
  },
  {
    label: "My Managers",
    path: "/my-managers",
    api_group: 3,
    roles: ["denora_user"],
    component: DenoraPage 
    
  },
  {
    label: "My Colleagues",
    path: "/my-colleagues",
    api_group: 11,
    roles: ["denora_user"],
    component: DenoraPage 
    
  },
  {
    label: "My Company",
    path: "/my-company",
    api_group: 12,
    roles: ["denora_user"],
    component: DenoraPage 
    
  },
  {
    label: "Time Trend Analysis",
    path: "/time-trend-analysis",
    roles: ["user_admin"],
    subItems: [
      {
        label: "Impact Analysis",
        path:"/impact",
        roles: ["user_admin"],
        component: ImpactAnalysis
      },
      {
        label: "Longitudinal Analysis",
        path:"/longitudinal",
        roles: ["user_admin"],
        component: LongitudinalAnalysis
      },
    ],
  },
  {
    label: "Analytics",
    path: "/analytics",
    roles: ["user_admin"],
    subItems: [
      {
        label: "Participation rate",
        path:"/participation",
        roles: ["user_admin"],
        component: Participation
      },
      {
        label: "Compilation time",
        path:"/compilation",
        roles: ["user_admin"],
        component: Compilation
      },
      {
        label: "Time distribution of responses",
        path:"/time-distibution",
        roles: ["user_admin"],
        component: Distribution
      }
    ],
  }
];

export const projectSettingsMenu = [
  {
    label: "Employees",
    path: `${paths.project_settings}/employees`,
    roles: ["user_admin"],
    link: "/project-employees",
    component: ProjectEmployees
  },
  
];


export const settings = [
  {
    path: paths.settings + "/account",
    iconActive: "settings-1-selected.svg",
    iconInActive: "settings-1.svg",
  },
  {
    path: paths.settings + "/account_security",
    iconActive: "settings-2-selected.svg",
    iconInActive: "settings-2.svg",
  },
  {
    path: paths.settings + "/account_settings",
    iconActive: "settings-3-selected.svg",
    iconInActive: "settings-3.svg",
  },
];


export const accountMenu = [
  {
    label: "Profile",
    path: paths.settings + "/profile",
    roles: ["user_admin", "trustie_user", "denora_user"],
    component: Account
  },
  {
    label: "Security and Privacy",
    path: paths.settings + "/security",
    roles: ["user_admin", "trustie_user", "denora_user"],
    component: AccountSecurity
  },
  {
    label: "Settings",
    path: paths.settings + "/settings",
    roles: ["user_admin", "trustie_user", "denora_user"],
    component: AccountSetting
  },
  
];